import React from 'react';
import "./telegram.scss"

const Telegram = () => {
    return ( 
        <div className='telegram' onClick={()=>{window.open("https://t.me/wealthgrowbrokerage")}}>
            <i className="fab fa-telegram"/>
            <span>
                Connect to support
            </span>
        </div>
     );
}
 
export default Telegram;