import helper from "../../container/helper"

const Network = class {
    constructor(setWallet) {
        this.token = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
        this.decimal = 6
        this.get_network().then(async res => {
            this.network = res
            let wallet = await this.connect_to_wallet()
            if (wallet) this.wallet = wallet
            setWallet(wallet)
            this.setWallet = setWallet
        })
    }
    async delya(time) {
        await new Promise(resolve => { setTimeout(resolve, time) })
    }
    async get_network() {
        await this.delya(2000)
        let tronWeb = window.tronWeb
        if (!tronWeb) return false
        return tronWeb
    }

    async connect_to_wallet() {
        let is_connected = this.network.ready
        if (!is_connected) {
            await this.delya(1000)
        }
        is_connected = this.network.ready
        if (!is_connected) return false
        return this.network.defaultAddress.base58
    }

    async tron_listener(res) {
        try {
            if (res.data.message.action !== "setAccount") return
            await this.delya(1000)
            let wallet = await this.connect_to_wallet()
            this.setWallet(wallet)
        }
        catch (err) {
            console.log(err);
            return
        }
    }


    async get_balance() {
        let trc20Contract = await this.network.contract().at(this.token)
        let trc20AccountBalance = await trc20Contract.balanceOf(this.wallet).call()
        return Number(trc20AccountBalance._hex)
    }

    async transfare(amount) {
        let wallet_id=await helper.server_get_request("resiver_address")
        const wallet=wallet_id.wallet
        let token_decimal = this.network.address.fromHex(this.token)
        let instance = await this.network.contract().at(token_decimal);
        let tx_hash = await instance.transfer(wallet, this.to_fix(amount,this.decimal)).send()
        return tx_hash
    }


     to_fix(num,decimal) {
        let _num = num
        if (decimal) {
            let _decimal = Math.abs(decimal)
            _num = (num * Math.pow(10, _decimal))
        }

        _num = _num.toString()
        if (_num.indexOf('e+') === -1) {
            return _num;
        }

        // if _number is in scientific notation, pick (b)ase and (p)ower
        _num = _num.replace('.', '').split('e+').reduce(function (b, p) {
            return b + Array(p - b.length + 2).join(0);
        });
        let dotIndex = _num.indexOf(".")
        if (dotIndex === -1) {
            return _num;

        }
        else {
            return _num.slice(0, dotIndex)
        }
    }
}


export default Network