import React, { Fragment, useContext } from 'react';

import './nav.css'
import { Context } from '../../container/Context';
import helper from '../../container/helper';

const Nav = () => {


    const context = useContext(Context)

    const { wallet } = context

    const link = [
        { name: 'Home', onClick: () => { helper.redirect("/") } },
        // { name: 'Networking', onClick: () => { helper.redirect("/network") } },
        { name: 'Terms and conditions', onClick: () => { helper.redirect("/terms") } },
    ]


    const open_bars = () => {
        const bars = document.querySelector('.nav_bars')
        bars.classList.add('open')
    }

    const CloseBars = () => {
        const bars = document.querySelector('.nav_bars')
        bars.classList.remove('open')
    }

    const close_bars = (e) => {
        let isnav = e.closest('.nav')
        if (!isnav) {
            CloseBars()
        }
    }


    const check_redirect = async () => {
        let data = await helper.server_get_request(`invest/invest_list/${wallet}`)
        if(!data.status)return
        if (data.data.length > 0) helper.redirect("/dashboard")
    }




    return (
        <Fragment>
            <div className='bg_nav'>
                <div className='nav_hide'>
                    <div className='nav_res container'>
                        <div className='bars'><i className='fas fa-bars' onClick={open_bars}></i></div>
                        <div><img src="../img/logo.png" alt="" /></div>
                    </div>
                </div>
                <div className='nav_bars' onClick={(e) => close_bars(e.target)}>
                    <div className='nav container'>
                        <div>
                            <div className='nav_img'><img src="../img/logo-2.png" alt="" /></div>
                        </div>
                        <div className='link'>
                            {link.map(each => (
                                <div>
                                    <div><h2 onClick={each.onClick}>{each.name}</h2></div>
                                </div>
                            ))}
                        </div>
                        {wallet ?
                            <div className='wallet' onClick={check_redirect}><p> <i className="fas fa-user p2"></i>My Dashboard</p></div>
                            :
                            <div className='wallet'><p>wallet not connected</p></div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Nav;