import React, { useEffect, useState } from 'react';
import Overly from '../../overly/Overly';
import helper from '../../../container/helper';


const UserAdmin = () => {

    const [popUp, setpopUp] = useState(false)
    const [users, setUsers] = useState([])

    const create_new_user = async () => {

        let wallet = document.querySelector("#wallet").value
        let max = document.querySelector("#max").value
        let period = document.querySelector("#period").value
        let ref = document.querySelector("#ref").value
        const data = await helper.server_post_request("admin/new_user", { wallet_id: wallet, max, period ,ref})
        if (data.status) {
            const { ref_code } = data.data
            setpopUp({ ref_code })

        }
    }

    const fetch = async () => {
        const data = await helper.server_get_request("admin/users")
        setUsers(data.data)
    }

    useEffect(fetch, [])

    return (

        <div className='user-admin'>

            <div className="title">
                Users
            </div>
            <div className="users-list">
                <ul>
                    <li>
                        <div>#</div>
                        <div>Balance</div>
                        <div>Wallet</div>
                        <div>Total Earn</div>
                        <div>Reffral Code</div>
                    </li>
                    {
                        users.map((user, index) => 
                            <li>
                                <div>{index+1}</div>
                                <div>{user.balance}</div>
                                <div>{user.wallet_id}</div>
                                <div>{user.total_earn}</div>
                                <div>{user.referral_code}</div>
                            </li>
                        )
                    }
                </ul>
            </div>
            <hr />
            <div className="label new-invest" onClick={() => { setpopUp(true) }}>
                <i className="fas fa-plus" /> new User
            </div>
            {popUp ?
                <Overly close={() => { setpopUp(false) }}>
                    <div className="o-title">
                        Add new User
                    </div>
                    <div className="input-u">
                        <div className="lab">
                            wallet id:
                        </div>
                        <input type="text" id="wallet" />
                    </div>
                    <div className="input-u">
                        <div className="lab">
                            start invest amount:
                        </div>
                        <input type="number" id="max" />
                    </div>
                    <div className="input-u">
                        <div className="lab">
                            plan period:
                        </div>
                        <input type="number" id="period" />
                    </div>
                    <div className="input-u">
                        <div className="lab">
                            ref code:
                        </div>
                        <input type="text" id="ref" />
                    </div>
                    <div className="submit-u">
                        <div className="btn" onClick={create_new_user}>
                            Add User
                        </div>

                        {popUp.ref_code ? <div className='p1'>new user ref code :{popUp.ref_code}</div> : null}
                    </div>
                </Overly>
                : null
            }

        </div>

    );
}

export default UserAdmin;