import React, { Fragment, useEffect } from 'react';

import './land.css'
import helper from '../../container/helper';

const LandPage = () => {

    const inscription = [
        {
            title: 'Trade in financial markets', summery: `our team has experts and experienced people in this field who have a positive resume and excellent output in forex and crypto currency markets` },
        { title: 'Investing in real estate', summery: 'A team with more than 9 years of experience in the field of real estate, who have received positive and high results from this market with their very high knowledge in this field' },
        { title: 'Entrepreneurship', summery: 'Our team has creative people who always create entrepreneurship for capable people by creating the best ideas and prevent the cycle of unemployment' },
    ]



    const view=()=>{
        helper.server_get_request("user/view")
    }

    useEffect(view,[])

    return (
        <Fragment>
            <div className='bg_land_page '>
                <div className='container'>
                    <div className='land_text '>
                        <div className='land_left_div'>
                            <div><h1>Invest with Confidence, Trust the Security of Decentralization</h1></div>
                            <div><p>on Tron chain  With a fixed and steady Monthly income!</p></div>
                            <div className='land_but'><button><h2 onClick={() => { helper.scroll_to_element("plan") }}>Lets Go</h2></button></div>
                        </div>
                        <div className='land_img'>
                            <img src="../img/3112677-removebg-preview.png" alt="" />
                        </div>
                    </div>
                    <div className='inscription'>
                        {inscription.map(each => (
                            <div className='each_inscrip'>
                                <div><h2>{each.title}</h2></div>
                                <div><p>{each.summery}</p></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default LandPage;