import React from 'react';
import helper from '../../../container/helper';


const Setting = () => {


    const change=()=>{
        let cur=document.querySelector("#cur").value
        let new_pass=document.querySelector("#new").value
        helper.server_post_request("admin/change_password",{cur_password:cur,new_password:new_pass})
        
    }

    return ( 
        <div className='setting' >
            <div className="title">
                Setting
            </div>

            <div className="label">
                Change Admin Panel Password
            </div>

            <div className="input">
                <div className="lab">Current Password:</div>
                <input type="password" id="cur"/>
            </div>
            <div className="input">
                <div className="lab">New Password:</div>
                <input type="password" id="new"/>
            </div>
            <div className="submit" onClick={change}>
                Change Password
            </div>
            <hr/>
        </div>
     );
}
 
export default Setting;