import axios from "axios"
import { NotificationManager } from "react-notifications"

const helper = {

    server: "https://wealthgrowbrokerage.com:5000",
    // server: "http://localhost:5000",

    async server_get_request(url, headers) {
        const { data } = await axios.get(`${this.server}/${url}`, { headers })
        if(data.msg){
            NotificationManager[data.status ? "success":"error"](data.msg)
        }
        return data
    },
    async server_post_request(url, data, headers) {
        const { data: res } = await axios.post(`${this.server}/${url}`, data, { headers })
        if(res.msg){
            NotificationManager[res.status ? "success":"error"](res.msg)
        }
        return res
    },

     date_handler(date){
        let d=new Date(date)
        let y=d.getFullYear()
        let m=d.getMonth()+1
        let day=d.getDate()
        return `${y}/${m}/${day}`
    },
    redirect(path){
        return window.location.hash=path
    },
    scroll_to_element(id){
        let element=document.querySelector(`#${id}`)
        if(!element)return
        let offset=element.offsetTop
        window.scroll({behavior:"smooth",top:offset})
    }
}

export default helper