import React from 'react';
import "./overly.scss"

const Overly = ({children,close}) => {
    return ( 
        <div className='overly' >
            <div className="content">
                <div className="close">
                    <i className="fas fa-times red" onClick={close}></i>
                </div>
                {children}
            </div>
        </div>
     );
}
 
export default Overly;