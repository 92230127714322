import React, { useEffect, useState } from 'react';
import AdminDashBoardNav from './admin-dashboard-nav/AdminDashboardNav';
import AdminDashboard from './admin-components/AdminDashboard';
import AdmiInvests from './admin-components/AdminInvests';
import Requests from './admin-components/Requests';
import "./admin.scss"
import AdminSupport from './admin-components/Support';
import UserAdmin from './admin-components/User';
import Setting from './admin-components/Setting';
import helper from '../../container/helper';




const Admin = () => {

    const check_token = async () => {
        let token = localStorage.getItem("admin_token")
        if (!token) return helper.redirect("/log_in")
        const data = await helper.server_post_request("admin/confirm_token", { token })
        if (!data.status) helper.redirect("/log_in")
    }
    
    
    
    useEffect(() => {
        // check_token()
    }, [])
    

    const [stage, setStage] = useState(0)

    const components = [
        <AdminDashboard />,
        <AdmiInvests />,
        <Requests />,
        <AdminSupport />,
        <UserAdmin />,
        <Setting />
    ]

    return (
        <div className='dashboard'>
            <AdminDashBoardNav setStage={setStage} />
            <div className="container">
                <div className="dashboard-content">
                    <div className="main-content stages">
                        {components[stage]}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;