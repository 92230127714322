import React from 'react';
import Nav from '../nav/Nav';
import "./network-plans.scss"

const NetworkPlans = () => {


    const tabel = [
        [3, 5], [9, 4], [27, 3], [81, 2], [243, 1], [729, 0.5], [2187, 0.5], [6561, 0.5], [19683, 0.5], [59049, 0.5]
    ]

    return (
        <div className='network-plans'>

            <div className='terms'>
                <Nav />
                <div className="content">
                    <div className="container text">
                        <div className="t-title">
                            Networking Plans and Reward
                        </div>
                        <div className="img">
                            <img src="/img/network.png" alt="" />
                        </div>
                        <div className="each-term">
                            Reward plans for users who help us in the development of this project
                        </div>
                        <div className="each-term">
                            1.Unilevel plan
                            <br />
                            In this plan, each person can attract up to 3 investors and receive up to 10 layers of commission without balance, the percentage of profits is as follows:
                        </div>

                        <div className="each-term">
                            Unilevel plan Reward table:  <br />
                            <ul className='network-tabel'>
                                <li>
                                    <div>Level</div>
                                    <div>subsets</div>
                                    <div>reward</div>
                                </li>
                                {
                                    tabel.map((t,n) =>
                                        <li>
                                            <div>{n+1}</div>
                                            <div>{t[0]}</div>
                                            <div>{t[1]}%</div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>

                        <div className="each-term">
                            2.matching bonus:
                            <br />
                            In this plan, each user from the first 3 people she introduced will receive 5% of the investment profit of the 3 people on a monthly basis until the end of their contract.
                        </div>
                        <div className="each-term">
                            3.Big prize:
                            <br />

                            When the total investment of each line reaches 5 thousand dollars and the total reaches 15 thousand dollars, the user receives 5% profit from the total investment (this plan does not include leveling, and if it goes beyond 10 levels, the investment volume is still calculated can be)e
                        </div>


                    </div>
                </div>
            </div>

        </div>
    );
}

export default NetworkPlans;