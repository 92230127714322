import React, { createContext, useEffect, useState } from 'react'
import Network from '../component/invest/network';
export const Context = createContext();

const ContextProvider = (props) => {
    const [show,setShow]=useState(false)
    const [wallet,setWallet]=useState(null)
    const [network,setNetwork]=useState(null)
    const [defRef,setDefRef]=useState(null)
    useEffect( ()=>{
        let network= new Network(setWallet)
        setNetwork(network)
        window.addEventListener("message", (e)=>{network.tron_listener(e)})
        return()=>{
            window.removeEventListener("message",  (e)=>{network.tron_listener(e)}, false)
        }
    },[])


    return (
        <Context.Provider value={{
            show,setShow,
            wallet,setWallet,
            network,
            defRef,setDefRef
        }}>
            {props.children}
        </Context.Provider>
    )
}


export default ContextProvider