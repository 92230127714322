import React, { Fragment, useContext } from 'react';
import { Route, HashRouter } from "react-router-dom"
import { Context } from './container/Context';
import MainPage from './component/main-page/MainPage';
import DashBoard from './component/dashboard/Dashboard';
import Popap from './component/popap/Popap';
import Admin from './component/admin/Admin';
import LogIn from './component/admin/AdminLogIn';
import Terms from './component/terms/Terms';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import NetworkPlans from './component/network-plans/NetworkPlans';
import Ref from './component/ref/Ref';

const App = () => {

  const context = useContext(Context)
  return (
    <Fragment>
      <NotificationContainer/>
       {context.show === false ? '' :<div><Popap/></div>}
      <HashRouter>
        <Route path="/" exact component={MainPage} />
        <Route exact path="/dashboard" component={DashBoard}/>
        <Route exact path="/admin" component={Admin}/>
        <Route exact path="/log_in" component={LogIn}/>
        <Route exact path="/terms" component={Terms}/>
        <Route exact path="/network" component={NetworkPlans}/>
        <Route  path="/ref" component={Ref}/>
      </HashRouter>
    </Fragment>
  );
}

export default App;