import React, { useContext, useEffect, useState } from 'react';
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';


const Requests = () => {

    const [req, setReq] = useState([])
    const context = useContext(Context)
    const { wallet } = context

    const fetch = async () => {
        const data = await helper.server_get_request(`user/requests/${wallet}`)
        setReq(data.data.requests)
    }



    useEffect(fetch, [])


    return (
        <div className='requests'>
            <div className="title">
                Requests
            </div>
            <ul className="req-list">
                <li>
                    <div>#</div>
                    <div>type</div>
                    <div>date</div>
                    <div>amount</div>
                    <div>message</div>
                    <div>status</div>
                </li>

                {req.map((req, index) => {
                    const { amount, dis, status, msg, date } = req
                    return (
                        <li>
                            <div>{index + ``}</div>
                            <div>{dis}</div>
                            <div>{helper.date_handler(date)}</div>
                            <div>{amount}</div>
                            <div>{msg}</div>
                            <div>{status === 0 ?
                                <span className='yellow'>Pending</span> :
                                status === 2 ? <span className='green'>Confirmed</span> :
                                    <span className='red'>Rejected</span>}</div>
                        </li>
                    )

                })}
            </ul>
        </div>
    );
}

export default Requests;