import React, { useState } from 'react';
import "./invest.scss"
import ConnectToWallet from './stagest/ConnectToWallet';
import CreateContract from './stagest/CreateContract';
import Payment from './stagest/Payment';
import PayRes from './stagest/PayRes';
import RefCode from './stagest/RefCode';

const Invest = () => {


    const [stage, setStage] = useState(0)
    const [data, setData] = useState({})


    const stages = [
        <ConnectToWallet setStage={setStage} setData={setData} />,
        <RefCode setStage={setStage} setData={setData} data={data}/>,
        <CreateContract setStage={setStage} setData={setData} data={data}/>,
        <Payment data={data} setStage={setStage} setData={setData} />,
        <PayRes data={data} setStage={setStage} setData={setData} />,
    ]

    return (

        <div className='invest'>
            {stages[stage]}
        </div>

    );
}

export default Invest;