import React, { useEffect, useState } from 'react';
import Overly from '../../overly/Overly';
import helper from '../../../container/helper';


const AdminSupport = () => {

    const [popUp, setPopUp] = useState(false)
    const [support,setSupport]=useState([])

    const fetch=async ()=>{
        const data=await helper.server_get_request("admin/supports")
        const {supports}=data.data
        setSupport(supports)
    }

    useEffect(fetch,[])


    const answer_question=async ()=>{
        const text=document.querySelector("#answer").value  
        await helper.server_post_request("admin/answer_support",{
            text,
            answer_to:popUp.sup._id

        })
        setPopUp(false)
        fetch()
    }


    return (
        <div className='admin-support' >
            <div className="title">
                Support
            </div>
            <ul className="support-tabel">
                <li>
                    <div>#</div>
                    <div>user</div>
                    <div>title</div>
                    <div>date</div>
                    <div>action</div>

                </li>

                {support.map((sup,index)=>{
                    const {title,date,wallet_id}=sup
                    return(
                        <li>
                        <div>{index+1}</div>
                        <div>{wallet_id.slice(0,5)}...</div>
                        <div>{title}</div>
                        <div>{helper.date_handler(date)}</div>
                        <div className='action'>
                            <i className="fas fa-info" title='show message' onClick={()=>{setPopUp({type:"show",sup})}}/>
                            <i className="fas fa-envelope green" title='answer message' onClick={()=>{setPopUp({type:"answer",sup})}}/>
                        </div>
    
                    </li>
                    )
                })}
            </ul>
            <hr />
            {popUp.type === "show" ?

                <Overly close={()=>{setPopUp(false)}}>
                    {popUp.sup.msg}
                </Overly>
                : popUp.type === "answer" ?
                    <Overly close={()=>{setPopUp(false)}}>
                        <>
                            <div className="o-title">
                                answer question
                            </div>
                            <div className="input-area">
                                <textarea placeholder='request result' id="answer" />
                            </div>
                            <div className="buttons">
                                <div className="submit" onClick={answer_question}>
                                    send answer
                                </div>
                            </div>
                        </>
                    </Overly>
                    : null

            }
        </div>
    );
}

export default AdminSupport;