import React, { Fragment,useState,useContext } from 'react';
import { Context } from '../../container/Context';
import Popap from '../popap/Popap';

import './invest.css'
const Investment = () => {
    const context=useContext(Context)

    const plans = [
        { name: 'Bronze Plan', roi: ' Monthly ROI ', change: 5, title: ['Dividends ever y second', 'Grand prize for networking', 'Dividends ever y second'] ,plan:3},
        { name: 'Silver Plan', roi: ' Monthly ROI ', change: 6.5, title: ['Dividends ever y second', 'Grand prize for networking', 'Dividends ever y second'] ,plan:6},
        { name: 'Golden Plan', roi: ' Monthly ROI ', change: 8, title: ['Dividends ever y second', 'Grand prize for networking', 'Dividends ever y second'] ,plan:9},
        { name: 'Diamond Plan', roi: ' Monthly ROI ', change: 10, title: ['Dividends ever y second', 'Grand prize for networking', 'Dividends ever y second'] ,plan:12},
    ]
    return (
        <Fragment>
            <div className='investment ' id="plan">
                <div><h1>Investment Plans</h1></div>
                <div className='invest_plan container'>
                    {plans.map(each => (
                        <div className='each_invest'>
                            <div><h2>{each.name}</h2></div>
                            <div className='roi'>{each.roi}</div>
                            <div className='change'><h2>{each.change}%</h2></div>
                            <div>{each.title.map(tit => (
                                <div><ul>
                                    <li>{tit}</li>
                                </ul></div>
                            ))}</div>
                            <div><button onClick={()=>context.setShow(each)}>Invest</button></div>
                        </div>
                    ))}
                </div>
            </div>
               
        </Fragment>
    );
}

export default Investment;