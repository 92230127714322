import React, { useEffect, useState } from 'react';
import helper from '../../../container/helper';


const AdminDashboard = () => {


    const [dash, setDash] = useState({})
    const [invests, setInvest] = useState([])
    const [req, setReq] = useState([])

    const fetch = async () => {
        let dashboard = await helper.server_get_request("admin/dashboard")
        setInvest(dashboard.data.invests)
        setDash(dashboard.data.dashbord_info)
        setReq(dashboard.data.requests)

    }

    useEffect(() => {
        fetch()
    }, [])


    const { active_invest, ex_invest, invest_count, total_pay, users_balance, users_earn, users_submit,view } = dash

    return (
        <div className='main-dashboard'>
            <div className='title'>
                Dashboard
            </div>
            <div className="wallet-info">
                <div className="info">
                    <div className="lab">
                        user submited:
                    </div>
                    <div className="value">
                        {users_submit || 0}
                    </div>

                </div>
                <div className="info">
                    <div className="lab">
                        confirmed invests (USDT):
                    </div>
                    <div className="value">
                        {active_invest || 0}
                    </div>
                </div>
                <div className="info">
                    <div className="lab">
                        total Pay:
                    </div>
                    <div className="value">
                        {total_pay || 0}
                    </div>
                </div>
                <div className="info">
                    <div className="lab">
                        Invests Count:
                    </div>
                    <div className="value">
                        {invest_count || 0}
                    </div>
                </div>
                <div className="info">
                    <div className="lab">
                        expierd invests:
                    </div>
                    <div className="value">
                        {ex_invest || 0}
                    </div>
                </div>


                <div className="info">
                    <div className="lab">
                        total User earn:
                    </div>
                    <div className="value">
                        {users_earn}
                    </div>
                </div>
                <div className="info">
                    <div className="lab">
                        users Balance:
                    </div>
                    <div className="value">
                        {users_balance || 0}
                    </div>
                </div>
                <div className="info">
                    <div className="lab">
                        web site view:
                    </div>
                    <div className="value">
                        {view}
                    </div>
                </div>
            </div>
            <hr />
            <div className="invests">
                <div className="label">
                    <i className="fas fa-money-check" />Last 5 Invests
                </div>
                <ul className="invest-tabel">
                    <li>
                        <div>#</div>
                        <div>ID</div>
                        <div>Amount</div>
                        <div>Plan</div>
                        <div>Start</div>
                        <div>End</div>
                    </li>


                    {invests.map((inv,i) =>
                        <li>
                            <div>{i+1}</div>
                            <div>{inv.id}</div>
                            <div>${inv.amount}</div>
                            <div>{inv.plan.plan_period}M</div>
                            <div>{helper.date_handler(inv.invest_date.start)}</div>
                            <div>{helper.date_handler(inv.invest_date.end)}</div>
                        </li>
                    )}
                </ul>

            </div>
            <hr />
            {/* <div className="invests">
                <div className="label">
                    <i className="fas fa-check" />Pending Users Request
                </div>
                <ul className="invest-tabel">
                    <li>
                        <div>#</div>
                        <div>User</div>
                        <div>Type</div>
                        <div>Amount</div>
                        <div>Date</div>
                        <div>Min</div>
                    </li>

                    <li>
                        <div>1</div>
                        <div>123</div>
                        <div>Withdrawl</div>
                        <div>$120</div>
                        <div>2022/3/12</div>
                        <div>120 min ago</div>
                    </li>
                </ul>

            </div>
            <hr /> */}


        </div>
    );
}

export default AdminDashboard;