import React from 'react';
import helper from '../../container/helper';


const LogIn = () => {


    const logIn = async (e) => {
        e.preventDefault()
        const password = document.querySelector("#password").value
        let data = await helper.server_post_request("admin/log_in", { password })
        if (data.status) {
            const { token } = data
            localStorage.setItem("admin_token", token)
            helper.redirect("/admin")
        }
        else {
            return
        }
    }




    return (
        <div className='log-in'>
            <div className="content">
                <div className="title">
                    Welcome
                </div>
                <div className="lab">
                    pleas Enter Admin Panle Password
                </div>
                <form onSubmit={(e)=>{logIn(e)}}>

                    <div className="input">
                        <input type="password" id="password" />
                    </div>
                    <div className="submit">
                        <button className="btn">
                            Log In
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
}

export default LogIn;