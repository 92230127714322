import React, { useContext, useState } from 'react';
import { Context } from '../../../container/Context';
import helper from '../../../container/helper';


const ConnectToWallet = ({ setStage, setData }) => {

    const context = useContext(Context)
    const { wallet } = context


    const next_stage = async () => {
        if (!wallet) return
        const status = await helper.server_post_request("user", { wallet_id: wallet,create:false })
        setData(prv => { return { ...prv, wallet } })
        if (status.data.is_exist) {
            return setStage(prv => { return prv + 2 })

        }
        setStage(prv => { return prv + 1 })
    }




    return (

        <div>
            <div className="title">
                Connect to tron wallet
            </div>
            <div className="content">
                <div className="img">
                    <img src="/img/wallet.png" alt="" />
                </div>
                <div className="dis">
                    please connect to Tron wallet
                </div>
                <div className="wallet_address">
                    <input className='text-input' type="text" disabled value={wallet ? wallet : "Wallet not connected"} />
                </div>
                <div className="btn" onClick={next_stage}>
                    <button disabled={wallet ? false : true}>
                        Contnue
                    </button>
                </div>
            </div>
        </div>

    );
}

export default ConnectToWallet;