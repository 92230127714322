import React, { Fragment, useContext, useEffect, useState } from 'react';

import './accInfo.css'
import helper from '../../container/helper';
import { Context } from '../../container/Context';

const AccountInfo = () => {

    const context = useContext(Context)
    const [invest, setInvest] = useState({ in: 0, out: 0 })
    const { wallet } = context
    const fetch = async () => {
       try{
        if (!wallet) return
        let data = await helper.server_get_request(`user/dashboard/${wallet}`)
        const {user,invests}=data.data.result
        if(invests.length === 0)return
        let _invests=invests.map(e=>e.amount)
        let total=_invests.reduce((a,b)=>{return a+b.amount})
        let new_inv={in:total,out:user.total_earn}
        setInvest(new_inv)
       }
       catch{
        return
       }
    }

    useEffect(fetch,[wallet])

    return (
        <Fragment>
            <div className='account_info'>
                <div><h1>My investments</h1></div>
                <div className='container'>
                    <div className='total'>
                        <div className='my_total'>
                            <div><img src="../img/investment.png" alt="" /></div>
                            <div>My total investment :</div>
                            <div><h2>{invest.in} USDT</h2></div>
                        </div>
                        <div>
                            <div><img src="../img/growth.png" alt="" /></div>
                            <div>Total dividends so far:</div>
                            <div><h2>{invest.out} USDT</h2></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default AccountInfo;