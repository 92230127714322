import React, { useContext, useEffect, useState } from 'react';
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';


const InBox = () => {
    const context=useContext(Context)
    const {wallet}=context

    const [box,setBox]=useState([])
    const fetch=async ()=>{
        const data=await helper.server_get_request(`user/msg_box/${wallet}`)
        setBox(data.data)
    }

    useEffect(fetch,[])

    return (
        <div className='in-box'>
            <div className="title">
                Message Box
            </div>
            <ul className="list-msg">
                <li>
                    <div>#</div>
                    <div>Title</div>
                    <div>date</div>
                    <div>message</div>
                </li>
               
                {box.map((msg,index)=>
                     <li>
                     <div>{index+1}</div>
                     <div>{msg.title}</div>
                     <div>{helper.date_handler(msg.date)}</div>
                     <div>{msg.text}</div>
                 </li>
                    
                    )}
            </ul>
        </div>
    );
}

export default InBox;