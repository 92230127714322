import React, { useContext, useState } from 'react';
import { Context } from '../../../container/Context';
import { NotificationManager } from 'react-notifications';


const Payment = ({ data, setData,setStage }) => {

    const context = useContext(Context)
    const { show, network } = context
    const { amount, wallet } = data
    const [w8, setW8] = useState(false)

    const transfare = async () => {
        try {
            if (w8) return
            setW8(true)
            let balance = await network.get_balance()
            if (balance < amount){
                NotificationManager.error("Not enough Balance")
                setW8(false)
                return
            }
            let tx_hash = await network.transfare(amount)
            setData(prv => { return { ...prv, tx_hash } })
            setStage(prv => { return prv + 1 })
            setW8(false)
        }
        catch (err){
            console.log(err);
            setW8(false)
        }
    }

    return (
        <div className='payment content'>
            <div className="title">
                Overview and Payment
            </div>
            <div className="info">
                <div className="e-info">
                    <div className="label">
                        wallet address:
                    </div>
                    <div className="value">
                        {wallet}
                    </div>
                </div>
                <div className="e-info">
                    <div className="label">
                        invest value:
                    </div>
                    <div className="value">
                        {amount} USDT
                    </div>
                </div>
                <div className="e-info">
                    <div className="label">
                        invest plan durtation:
                    </div>
                    <div className="value">
                        {show.plan} Month
                    </div>
                </div>

                <div className="e-info">
                    <div className="label">
                        invest Monthly ROI:
                    </div>
                    <div className="value">
                        {show.change} % ({(data.amount * show.change) / 100} USDT)
                    </div>
                </div>
            </div>
            <div className="btn" >
                <button onClick={transfare}>
                    {w8 ? "Please Wait ..." : "Start Payment"}
                </button>
            </div>
        </div>
    );
}

export default Payment;