import React from 'react';
import Nav from '../nav/Nav';
import "./terms.scss"

const Terms = () => {
    return (
        <div className='terms'>
            <Nav />
            <div className="content">
                <div className="container text">
                    <div className="t-title">
                        Terms And Conditions
                    </div>
                    {/* <div className="each-term">
                        *The commission payment for the development plan (networking) is from Monday to Thursday every week
                    </div> */}
                    <div className="each-term">
                        *The minimum withdrawal is $20
                    </div>
                    <div className="each-term">
                        *At the end of each contract, the original capital is returned to the user
                    </div>
                    {/* <div className="each-term">
                        *The ceiling for receiving commissions for users who are active in the development plan (networking) is 10 times the investment amount (for example, someone who invests $100 can finally receive commissions up to $1000).
                    </div> */}
                    <div className="each-term">

                        *After the end of the 10x limit, the user is flashed out and has only 12 hours to reinvest in order to benefit from the commission on new investments, otherwise she will not receive commission on new investments
                    </div>
                    <div className="each-term">
                        *The minimum investment is $100
                    </div>
                    <div className="each-term">
                        *Investment interest is paid monthly
                    </div>
                    <div className="each-term">

                        *If the commission received by the user is more than twice her investment, the person does not have the right to cancel the contract until the end of the contract
                    </div>
                    <div className="each-term">
                    If the user requests to cancel the contract earlier than the end of the contract, the received profits and commissions of the development project will be deducted from the principal capital and the remaining capital will be deposited into the investor's wallet within 10 working days
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Terms;