import React, { useEffect, useState } from 'react';
import Overly from '../../overly/Overly';
import helper from '../../../container/helper';


const Requests = () => {

    const [popUp, setPopUp] = useState(false)
    const [req, setReq] = useState([])


    const fetch = async () => {
        const data = await helper.server_get_request("admin/requests")
        setReq(data.data.requests)
    }

    useEffect(fetch,[])


    const handel_request=async ()=>{
        let msg=document.querySelector("#msg").value
        await helper.server_post_request("admin/handel_request",{
            _id:popUp.id,
            msg,
            status:popUp.status?2:1
        })
        fetch()
        setPopUp(null)

    }


    return (
        <div className='user-requests'>
            <div className="title">
                Requests
            </div>
            <ul className="request-tabel">
                <li>
                    <div>#</div>
                    <div>type</div>
                    <div>Amount</div>
                    <div>Date</div>
                    <div>wallet Id</div>
                    <div>Action</div>

                </li>

               
                {req.map((e,index)=>
                    <li>
                        <div>{index+1}</div>
                        <div>{e.dis}</div>
                        <div>{e.amount}</div>
                        <div>{helper.date_handler(e.date)}</div>
                        <div>{e.wallet_id}</div>
                        <div className='action'>
                            <i className="fas fa-check green" onClick={()=>{setPopUp({id:e._id,status:true})}} />
                            <i className="fas fa-times red"  onClick={()=>{setPopUp({id:e._id,status:false})}}/>
                        </div>
                    </li>
                )}
            </ul>
            <hr />
            {popUp ?
                <Overly close={() => { setPopUp(false) }}>
                    <div className="o-title">
                        Handel Request({popUp.status?<span className='green'>Confirm</span>:<span className='red'>Reject</span>})
                    </div>
                    <div className="input-area">
                        <textarea placeholder='request result' id="msg" />
                    </div>
                    <div className="buttons">
                        <div className="submit" onClick={handel_request}>
                            confirm Request
                        </div>
                    </div>
                </Overly> :
                null
            }

        </div>
    );
}

export default Requests;