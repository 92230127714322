import React ,{ Fragment,useEffect,useContext }from 'react';

import './popap.css'
import { Context } from '../../container/Context';
import Invest from '../invest/Invest';

const Popap = ({children}) => {

    const context=useContext(Context)

    const close=(e)=>{
        const isoutinner = e.closest('.inner_pop')
        if (!isoutinner) {
            context.setShow(false)
        }
     }
    useEffect(() => {
        const close_on_esc=(e)=>{
            if (e.key === 'Escape') {
                context.setShow(false)
            }
        }
        window.addEventListener('keydown',close_on_esc);
        return ()=>{
            window.removeEventListener("keydown",close_on_esc)
        }
    }, [])
    return ( 
        <Fragment>
            <div className='outer_pop' onClick={(e) => close(e.target)}>
                <div className='inner_pop'>
                    <div className='times'><i className='fas fa-times' onClick={()=> context.setShow(false)}></i></div>
                    <div className='obj'> 
                    <Invest/>
                    </div>
                </div>
            </div>
        </Fragment>
     );
}
 
export default Popap;