import React from 'react';
import helper from '../../../container/helper';

const AdminDashBoardNav = ({setStage}) => {

    const toggle=()=>{
        document.querySelector(".dashboard-nav").classList.toggle("active")
    }

    return (
        <div className='dashboard-nav active'>
            <div className="content">
                <div className="head">
                    <div className="toggle">
                        <i className="fas fa-arrow-right" onClick={toggle} />
                    </div>
                    <div className="title">
                        Admin
                    </div>
                </div>
                <ul className="list">
                    <li onClick={()=>{setStage(0)}}>
                        <div className="icon">
                            <i className="fas fa-times"></i>
                        </div>
                        <div className="label">
                            Dashboard
                        </div>
                    </li>
                    <li onClick={()=>{setStage(1)}}>
                        <div className="icon">
                            <i className="fas fa-money-check"></i>
                        </div>
                        <div className="label">
                            Invests
                        </div>
                    </li>
                    <li onClick={()=>{setStage(2)}}>
                        <div className="icon">
                            <i className="fas fa-check"></i>
                        </div>
                        <div className="label">
                            Requests
                        </div>
                    </li>
                    <li onClick={()=>{setStage(3)}}>
                        <div className="icon">
                            <i className="fas fa-question"></i>
                        </div>
                        <div className="label">
                            Support
                        </div>
                    </li>
                  
                    <li onClick={()=>{setStage(4)}}>
                        <div className="icon">
                            <i className="fas fa-user"></i>
                        </div>
                        <div className="label">
                           Users
                        </div>
                    </li>
                    <li onClick={()=>{setStage(5)}}>
                        <div className="icon">
                            <i className="fas fa-cog"></i>
                        </div>
                        <div className="label">
                           Setting
                        </div>
                    </li>
                </ul>
                <div className="exit" onClick={()=>{helper.redirect("/")}}>
                    <i className="fas fa-power-off"></i>
                </div>
            </div>
        </div>
    );
}

export default AdminDashBoardNav;