import React, { useContext, useEffect } from 'react';
import { Context } from '../../container/Context';


const Ref = () => {


    const context=useContext(Context)

    useEffect(()=>{
        let url=window.location.hash
        url=url.split("/")
        let ref=url[2] || null
        context.setDefRef(ref)
        window.location.hash="/"
    },[])


    return (
        <div></div>
    );
}

export default Ref;