import React, { useEffect, useState } from 'react';
import helper from '../../../container/helper';


const AdmiInvests = () => {


    const [invests, setInvest] = useState([])
    const fetch = async () => {
        const data = await helper.server_get_request("admin/invests")
        setInvest(data.data.invests)
    }

    useEffect(fetch, [])

    return (
        <div className='panel-invests'>
            <div className="title">
                Invests
            </div>
            <ul className="invest-tabel">
                <li>
                    <div>#</div>
                    <div>ID</div>
                    <div >tx hash</div>
                    <div>Amount</div>
                    <div>Plan</div>
                    <div>ROI</div>
                    <div>Flash out</div>
                    <div>Start</div>
                    <div>End</div>
                </li>

             
                {invests.map((inv, index) => {
                    const { active, amount, id, flash_remain, invest_date, pay, plan } = inv
                    return (
                        <li>
                            <div>{index+1}</div>
                            <div className={active ? "green":"false"}>{id}</div>
                            <div 
                            onClick={()=>{
                                window.open(`https://tronscan.org/#/transaction/${pay.tx_hash}`)
                            }}
                            ><i className="fas fa-link"></i></div>
                            <div>${amount}</div>
                            <div>{plan.plan_period}M</div>
                            <div>{plan.persent}%</div>
                            <div>${flash_remain}</div>
                            <div>{helper.date_handler(invest_date.start)}</div>
                            <div>{helper.date_handler(invest_date.end)}</div>
                        </li>
                    )
                })}
            </ul>
            <hr />
        </div>

    );
}

export default AdmiInvests;