import React, { useContext, useEffect, useState } from 'react';
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';


const RefCode = ({ setStage, setData, data: prv_data }) => {


    const context = useContext(Context)
    const { defRef } = context

    useEffect(() => {
        if (defRef) {
            next_step(defRef)
        }
    }, [])


    const [loading, setLoading] = useState(false)

    const next_step = async (defRef) => {
        if (loading) return
        setLoading(true)
        let code = defRef || document.querySelector("#code").value
        if (code.length === 0) {
            code = "null"
        }
        let code_check = await helper.server_get_request(`user/check/${code}`)
        code = code_check.status
        const data = await helper.server_post_request("user", { wallet_id: prv_data.wallet, reference_code: code, create: true })
        setStage(prv => { return prv + 1 })
        setLoading(false)
        return data

    }



    return (
        <div>
            <div className="title">
                Welcome!
            </div>
            <div className="content">
                <div className="img">
                    <img src="/img/co.png" alt="" />
                </div>

                <div className="dis">
                    If you have referral code,enter it blow or leave this section blank and we will choose a random user for you.<br />
                    <span className='red'>
                        *Note that you will never be able to change your main branch
                    </span>
                </div>
                <div className="input-ref">
                    <input type="text" className='ref-code' id="code" defaultValue={context.defRef || ""} />
                </div>
                <div className="btn" >
                    <button onClick={() => { next_step(null) }}>
                        Contnue
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RefCode;