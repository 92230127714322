import React, { useContext, useEffect, useState } from 'react';
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';


const PayRes = ({ data }) => {

   const context=useContext(Context)

    const [res, setRes] = useState(null)

    const check_transaction = async () => {
        const { tx_hash, id } = data
        let res = await helper.server_post_request("invest/confirm_invest", { tx_hash, id })
        setRes(res)
    }

    useEffect(() => {
        check_transaction()
    }, [])

    return (
        <div className='pay-res content'>
            <div className="title">
                Payment Result
            </div>
            {!res ?
                <div className="loading">
                    <div className="img">
                        <img src="/img/loading.gif" alt="" />
                    </div>
                    <div className="sub-title">
                        checking transaction result.This process takes 15 seconds.please wait...
                    </div>
                </div> :
                <div className='loading'>
                    <div className="img">
                        <img src={res.status ? "/img/check.png" : "/img/times.png"} alt="" />
                    </div>
                    <div className="info">
                        Transaction {res.status ? "Confirmed successfuly" : "Faild"}
                    </div>
                    {
                        res.status ?

                            <div>
                                <div className="label">
                                    Your referral code:
                                </div>
                                <div className="r-code">
                                    <input type="text" className='text-input' disabled value={res.data?.referral_code}/>
                                </div>
                                <div className="btn">
                                    <button onClick={()=>{helper.redirect("/dashboard");context.setShow(false)}}>
                                        Go to Dashbord
                                    </button>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>

            }
        </div>
    );
}

export default PayRes;