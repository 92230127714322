import React, { Fragment, useContext, useEffect, useState } from 'react';
import Overly from '../../overly/Overly';
import { Context } from '../../../container/Context';
import helper from '../../../container/helper';


const Invests = () => {

    const [popUp, setpopUp] = useState({
        type: null
    })

    const [invest, setInvest] = useState([])

    const context = useContext(Context)
    const { wallet } = context
    const plans = [
        { name: 'Diamond Plan', roi: ' Monthly ROI ', change: 5, title: ['Dividends ever y second', 'Dividends ever y second', 'Dividends ever y second'], plan: 3 },
        { name: 'Diamond Plan', roi: ' Monthly ROI ', change: 6.5, title: ['Dividends ever y second', 'Dividends ever y second', 'Dividends ever y second'], plan: 6 },
        { name: 'Diamond Plan', roi: ' Monthly ROI ', change: 8, title: ['Dividends ever y second', 'Dividends ever y second', 'Dividends ever y second'], plan: 9 },
        { name: 'Diamond Plan', roi: ' Monthly ROI ', change: 10, title: ['Dividends ever y second', 'Dividends ever y second', 'Dividends ever y second'], plan: 12 },
    ]


    const fetch = async () => {
        const data = await helper.server_get_request(`invest/invest_list/${wallet}`)
        setInvest(data.data|| [])

    }

    useEffect(() => {
        fetch()
    }, [wallet])



    const cancel_invest = () => {
        const { inv } = popUp
        const { amount, flash_remain, id } = inv
        let amount_picked = (amount * 10) - flash_remain
        if (amount_picked > amount) return "notif"
        helper.server_post_request("invest/cancel_invest", { wallet_id: wallet, invest_id: id })
        fetch()
        // notif
        setpopUp(null)
    }

    const rehold =async () => {
        const { inv } = popUp
        const { id } = inv
        await helper.server_post_request("invest/re_hold", { wallet_id: wallet, invest_id: id })
        fetch()
        // notif
        setpopUp(null)
    }

    const checkout =async () => {
        const { inv } = popUp
        const { id } = inv
       await helper.server_post_request("invest/checkout", { wallet_id: wallet, invest_id: id })
        fetch()
        // notif
        setpopUp(null)
    }




    return (
        <div className='panel-invests'>
            <div className="title">
                Invests
            </div>
            <ul className="invest-tabel">
                <li>
                    <div>#</div>
                    <div>ID</div>
                    <div>Amount</div>
                    <div>Plan</div>
                    <div>ROI</div>
                    <div>Flash out</div>
                    <div>Start</div>
                    <div>End</div>
                    <div>Actions</div>
                </li>

                {invest.map((inv, index) => {
                    const { active, amount, flash_remain, invest_date, id, plan, penalty, checkout, flash } = inv
                    return (
                        <li>
                            <div>{index + 1}</div>
                            <div className={active ? "green" : "red"}>{id}</div>
                            <div>{amount}</div>
                            <div>{plan.plan_period}M</div>
                            <div>{plan.persent}%</div>
                            <div>${flash_remain}</div>
                            <div>{helper.date_handler(invest_date.start)}</div>
                            {penalty && !flash ?
                                <>
                                    <div className={penalty ? "red" : ""}>{helper.date_handler(invest_date.end)}</div>
                                    <div className='actions'>
                                        {!checkout ?
                                            <>
                                                <i className="fas fa-check green" title='checkout Invesment' onClick={() => { setpopUp({ inv, type: "checkout" }) }} />
                                                <i className="fas fa-sync yellow" title='checkout Invesment' onClick={() => { setpopUp({ inv, type: "reinvest" }) }} />
                                            </>
                                            :
                                            <div>Invest Expierd</div>
                                        }
                                    </div>
                                </> :
                                <>
                                    <div>{helper.date_handler(invest_date.end)}</div>
                                    <div className='actions'>
                                        {active && !flash ?
                                            <>
                                                <i className="fas fa-times red" title='Cancel Invesment' onClick={() => { setpopUp({ inv, type: "cancel" }) }} />
                                            </>
                                            :
                                            <div>Invest Expierd</div>
                                        }
                                    </div>
                                </>
                            }
                        </li>
                    )
                })}
            </ul>
            <hr />
            <div className="label new-invest" onClick={() => { setpopUp({ type: true }) }}>
                <i className="fas fa-plus" /> new Invest
            </div>
            {popUp ?

                <Fragment>

                    {popUp.type === "cancel" ?
                        <Overly close={() => { setpopUp(null) }}>
                            <div className="overly-title">
                                Cancel Invesment
                            </div>
                            <div className="info">
                                It May cost 5% - 35% Asset Lost.are you Shore?
                            </div>
                            <div className="confirm">
                                <div className='yes' onClick={cancel_invest}>Cancel Invesment</div>
                                <div className='no' onClick={() => { setpopUp(null) }}>No</div>
                            </div>
                        </Overly> : popUp.type === "reinvest" ?
                            <Overly close={() => { setpopUp(null) }}>
                                <div className="overly-title">
                                    ReHold Invesment
                                </div>
                                <div className="info">
                                    Do you want to Reinvest for 3 month?
                                </div>
                                <div className="confirm">
                                    <div className='yes-green' onClick={rehold}>Reinvest</div>
                                    <div className='no' onClick={() => { setpopUp(null) }}>No</div>
                                </div>

                            </Overly>
                            : popUp.type === "checkout" ?

                                <Overly close={() => { setpopUp(null) }}>
                                    <div className="overly-title">
                                        Chekout Invesment
                                    </div>
                                    <div className="info">
                                        Do you want to Checkout invest
                                    </div>
                                    <div className="confirm">
                                        <div className='yes-green' onClick={checkout}>Check out</div>
                                        <div className='no' onClick={() => { setpopUp(null) }}>No</div>
                                    </div>

                                </Overly> : popUp.type ?
                                    <Overly close={() => { setpopUp(null) }}>
                                        <div className="overly-title">
                                            Make new Invest
                                        </div>
                                        <div className="info">
                                            choose your plan
                                        </div>

                                        <div className="plans">
                                            {plans.map(each => (
                                                <div className='each-p'>
                                                    <div><h2>{each.name}</h2></div>
                                                    <div className=''>{each.roi}</div>
                                                    <div className=''><h2>{each.change}%</h2></div>
                                                    <div><button onClick={() => { context.setShow(each); setpopUp(null) }}>Invest</button></div>
                                                </div>
                                            ))}

                                        </div>

                                    </Overly>
                                    : null

                    }

                </Fragment>
                : null
            }
        </div>
    );
}

export default Invests;