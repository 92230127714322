import React, { useState } from 'react';
import DashBoardNav from './dashboard-nav/DashboardNav';
import "./dashboard.scss"
import MainDashBoard from './dashboard-components/MainDashboard';
import Invests from './dashboard-components/Invests';
import Support from './dashboard-components/Support';
import InBox from './dashboard-components/Inbox';
import Requests from './dashboard-components/Requests';
const DashBoard = () => {


    const [stage,setStage]=useState(0)

    const components=[
        <MainDashBoard/>,
        <Invests/>,
        <Requests/>,
        <Support/>,
        <InBox/>
    ]

    return (
        <div className='dashboard'>
            <DashBoardNav setStage={setStage}/>
            <div className="container">
                <div className="dashboard-content">
                    <div className="main-content stages">
                        {components[stage]}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashBoard;