import React, { useContext, useEffect, useState } from 'react';
import "./dashboard-nav.scss"
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';

const DashBoardNav = ({ setStage }) => {

    const toggle = () => {
        document.querySelector(".dashboard-nav").classList.toggle("active")
    }

    const [newMsg, setNewMsg] = useState(0)

    const context=useContext(Context)
    const {wallet}=context
    const fetch=async ()=>{
        const data=await helper.server_get_request(`user/box/${wallet}`)
        setNewMsg(data.data)
    }

    const read=()=>{
        helper.server_get_request(`user/read_box/${wallet}`)
    }

    useEffect(fetch,[])

    return (
        <div className='dashboard-nav active'>
            <div className="content">
                <div className="head">
                    <div className="toggle">
                        <i className="fas fa-arrow-right" onClick={toggle} />
                    </div>
                    <div className="title">
                        Panel
                    </div>
                </div>
                <ul className="list">
                    <li onClick={() => { setStage(0) }}>
                        <div className="icon">
                            <i className="fas fa-times"></i>
                        </div>
                        <div className="label">
                            Dashboard
                        </div>
                    </li>
                    <li onClick={() => { setStage(1) }}>
                        <div className="icon">
                            <i className="fas fa-money-check"></i>
                        </div>
                        <div className="label">
                            Invests
                        </div>
                    </li>
                    <li onClick={() => { setStage(2) }}>
                        <div className="icon">
                            <i className="fas  fa-check-circle"></i>
                        </div>
                        <div className="label">
                            Requests
                        </div>
                    </li>
                    <li onClick={() => { setStage(3) }}>
                        <div className="icon">
                            <i className="fas fa-question"></i>
                        </div>
                        <div className="label">
                            Support
                        </div>
                    </li>
                    <li onClick={() => { setStage(4) ;read();fetch()}}>
                        <div className="icon">
                            <i className="fas fa-envelope"></i>
                        </div>
                        <div className="label" >
                            Message Box
                           {newMsg ?  <span className='msg-after'>{newMsg}</span>:null}
                        </div>
                    </li>
                </ul>
                <div className="exit" onClick={() => { helper.redirect("/") }}>
                    <i className="fas fa-power-off"></i>
                </div>
            </div>
        </div>
    );
}

export default DashBoardNav;