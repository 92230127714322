import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../container/Context';
import helper from '../../../container/helper';


const CreateContract = ({ setStage, setData, data }) => {


    const [amount, setAmount] = useState(null)
    const [terms, setTerms] = useState(false)
    const [loading, setLoading] = useState(false)
    const context = useContext(Context)
    const [min, setMin] = useState(0)

    const { show } = context
    const contnue = async () => {
        if (loading) return
        setLoading(true)
        const { status, data } = await create_contract()
        const { id } = data
        if (!terms || !status) {setLoading(false);return}
        if (amount < min) {setLoading(false);return}
        setLoading(false)
        setData(prv => { return { ...prv, amount, id } })
        setStage(prv => { return prv + 1 })
    }
    const create_contract = async () => {
        let contract = {
            wallet_id: data.wallet,
            amount,
            plan: {
                persent: show.change,
                plan_period: show.plan
            },
        }
        const res = await helper.server_post_request("invest/new", contract)
        return res
    }


    let get_min = async () => {
        const { min } = await helper.server_get_request("invest/min")
        setMin(min)
    }


    useEffect(get_min, [])


    return (
        <div className='create-contract'>
            <div className="title">
                Sign Contract
            </div>
            <div className="content">
                <div className="img">
                    <img src="/img/contract.png" alt="" />
                </div>

                <div className="read-terms">
                    <input type="checkbox" onClick={() => { setTerms(!terms) }} className='mr' />
                    I have read and agree to the <a cl href="/#/terms" target='_blank'>terms and conditions</a>
                </div>
                <div className="inputs">
                    <div className="amount">
                        <div className="label">

                            How much do you want to invest?
                        </div>
                        <input type="number" className='text-input' onChange={(e) => { setAmount(e.target.value) }} />USDT

                    </div>
                </div>
                <div className="error">
                    {amount && amount < min ? `*The minimum investment is ${min} USDT` : ""}
                </div>

                <div className="btn" onClick={contnue}>
                    <button disabled={terms ? false : true}>
                        Contnue
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CreateContract;