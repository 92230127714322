import React, { Fragment } from 'react';
import Nav from '../nav/Nav';
import LandPage from '../land page/LandPage';
import Investment from '../Investment Plans/Investment';
import AccountInfo from '../My investment/AccountInfo';
import Footer from '../footer/Footer';
import Telegram from '../../telegram/Telegram';


const MainPage = () => {
    return (
        <Fragment>
            <Nav />
            <LandPage />
            <Investment />
            <AccountInfo />
            <Footer />
            <Telegram/>
        </Fragment>
    );
}

export default MainPage;