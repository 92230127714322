import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../container/Context';
import helper from '../../../container/helper';
import Overly from '../../overly/Overly';



const MainDashBoard = () => {


    const [dash, setDash] = useState({})
    const [invests, setInvest] = useState([])
    const [prize, setprize] = useState([])
    const [popUp, setpopUp] = useState(false)
    const [get, setGet] = useState(false)
    const [w, setW] = useState(false)
    const [cleanPrize, setCleanPrize] = useState({ amount: 0, persent: 0 })
    const context = useContext(Context)
    const { wallet } = context
    const fetch = async () => {
        try {
            const res = await helper.server_get_request(`user/dashboard/${wallet}`)
            const { qr } = res?.data?.result
            document.querySelector("#qr").src = qr
            let prize = await helper.server_get_request(`invest/prize/${wallet}`)
            if (prize.status) {
                setprize(prize.data)
                setGet(prize.get_prize)
                let all = [0].concat(prize.data.map(e => e.invests.amount))
                let clean_p = {
                    amount: all.reduce((a, b) => { return a + b }),
                    persent: all.reduce((a, b) => { return a + b }) * 100 / 15000
                }
                setCleanPrize(clean_p)
            }
            if (res.status) {

                const { result, status } = res.data
                if (!status) return
                const { referral_code, balance } = result.user
                setDash({ referral_code, balance })
                setInvest(result.invests)
            }
        }
        catch {
            helper.redirect("/")
        }
    }

    useEffect(() => {
        fetch()
    }, [wallet])


    const send_w_request = async () => {
        let amount = document.querySelector("#amount").value
        if (!amount || +amount < 20) return "notif"
        const data = await helper.server_post_request("user/withdraw", { wallet_id: wallet, amount })
        if (data.status) {
            setW(false)
            fetch()
            return "notif"
        }
    }


    const get_prize=async ()=>{
        setGet(false)
        await helper.server_get_request(`invest/clime_prize/${wallet}`)
        fetch()
    }

    return (
        <div className='main-dashboard'>
            <div className='title'>
                Dashboard
            </div>
            <div className="wallet-info">
                <div className="left">
                    <div className="wallet_id">
                        <div className="label">
                            wallet Id:
                        </div>
                        <div className="value">
                            {wallet ? wallet.slice(0, 10) + "..." : ""}
                        </div>
                    </div>
                    <div className="referral-code">
                        <div className="label">
                            Referral code :
                        </div>
                        <div className="value">
                            {dash?.referral_code || "No referral code"}
                        </div>
                    </div>
                    <div className="referral-code">
                        <div className="label">
                            Ballance :
                        </div>
                        <div className="value">
                            ${dash?.balance}
                            <div className="btn-dash" onClick={() => { setW(true) }}>
                                withdrawal
                            </div>
                        </div>

                    </div>
                </div>
                <div className="right">
                    <div className="qr-code">
                        <img alt="qr-code" id="qr" />
                    </div>
                    Your Referral QR-code
                </div>
            </div>
            <hr />
            <div className="invests">
                <div className="label">
                    <i className="fas fa-money-check" />Active Invests
                </div>
                <ul className="invest-tabel">
                    <li>
                        <div>#</div>
                        <div>ID</div>
                        <div>Amount</div>
                        <div>Plan</div>
                        <div>Start</div>
                        <div>End</div>
                    </li>


                    {invests?.map((inv, index) =>
                        <li>
                            <div>{index + 1}</div>
                            <div>{inv.id}</div>
                            <div>${inv.amount}</div>
                            <div>{inv.plan.plan_period}M</div>
                            <div>{helper.date_handler(inv.invest_date.start)}</div>
                            <div>{helper.date_handler(inv.invest_date.end)}</div>
                        </li>
                    )}
                </ul>

            </div>
            <hr />

            <div className="prize">
                <div className="label">
                    <i className="fas fa-check" /> Big Prize
                </div>
                <div className="img">
                    <img src="/img/treasure.png" alt="" />
                </div>
                <div className="label tac">
                    Rouad to $750 Prize pool
                </div>
                <div className="bar-container">
                    <span>
                        $0
                    </span>
                    <div className="bar">
                        <div className="comp" style={{ width: `${cleanPrize.persent}%` }}>

                        </div>
                    </div>
                    <span>
                        $15,000
                    </span>

                </div>
                <div className="cur label tac">
                    ${cleanPrize.amount} of 15,000 ({Math.round(cleanPrize.persent)}% Compleate)
                </div>
                {
                    get ?
                        <div className="climb" onClick={get_prize}>
                            Get the prize
                        </div> :
                        null
                }
                <div className="show-detile" >
                    <div className="btn" onClick={() => { setpopUp(true) }}>
                        Show More Detile
                    </div>
                </div>
            </div>
            {popUp ?
                <Overly close={() => { setpopUp(false) }}>
                    <ul className="prize-tabel">
                        <li>
                            <div>Line</div>
                            <div>Total Invest</div>
                            <div>Invest Count</div>
                        </li>
                        {prize.map((p, index) =>
                            <li>
                                <div>{index + 1}</div>
                                <div>${p.p_invests.amount}</div>
                                <div>{p.p_invests.count}</div>
                            </li>
                        )}

                    </ul>
                </Overly>
                : null
            }
            {
                w ?
                    <Overly close={() => { setW(false) }}>
                        <div className="o-title">
                            withdrawal
                        </div>
                        <div className="w">
                            <div className="dis">
                                Send withdrawal request to admin<br />
                                <div className="red">
                                    minimum amount of withdrawal is 20$
                                </div>
                            </div>

                            <div className="input">
                                <input type="number" placeholder='amount to withdrawal' id="amount" />
                            </div>
                            <div className="submit">
                                <div className="btn" onClick={send_w_request}>
                                    Send Request
                                </div>
                            </div>
                        </div>
                    </Overly>
                    : null
            }
        </div>
    );
}

export default MainDashBoard;