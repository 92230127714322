import React, { useContext, useEffect, useState } from 'react';
import helper from '../../../container/helper';
import { Context } from '../../../container/Context';


const Support = () => {


    const context = useContext(Context)
    const [sups, setSups] = useState([])

    const fetch = async () => {
        const data = await helper.server_get_request(`user/support_list/${context.wallet}`)
        setSups(data.data)
    }

    const send_sup_req = async () => {
        const title = document.querySelector("#title")
        const text = document.querySelector("#text")
        if (!text || !title) return "notif"
        await helper.server_post_request("user/support", { title: title.value, msg: text.value, wallet_id: context.wallet })
        //notif
        title.value = ""
        text.value = ""
        fetch()
    }


    useEffect(fetch, [])



    return (
        <div className='support'>
            <div className="title">
                Support
            </div>
            <div className="label p1">
                Submit new ticket for Support team
            </div>
            <div className="lab">
                ticket title:
            </div>
            <div className="input">
                <input type="text"  id="title"/>
            </div>
            <div className="lab">
                ticket message:
            </div>
            <div className="input">
                <textarea id="text"/>
            </div>

            <div className="submit btn-dash" onClick={send_sup_req}>
                submit new ticket
            </div>
            <div className="lab">
                you will ressive tickets answer in "Message Box" section
            </div>
            <hr />
            <div className="label p1">
                your support tickets
            </div>
            <ul className="list">
                <li className="head">
                    <div>#</div>
                    <div>title</div>
                    <div>Message</div>
                    <div>status</div>
                </li>
                {
                    sups.map((sup, index) => {
                        const { title, msg, status } = sup
                        return (
                            <li >
                                <div>{index+1}</div>
                                <div>{title}</div>
                                <div>{msg}</div>
                                <div className={status ?"green":"yellow"}>{status ? "Answerd":"Pending for answer"}</div>
                            </li>
                        )
                    }
                    )
                }
            </ul>
        </div>
    );
}

export default Support;